import React, { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import API from "../../middleware/Api";
import Auth from "../../middleware/Auth";
import {
  applyThemes,
  setSessionStatus,
  toggleSnackbar,
} from "../../redux/explorer";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function LoginByToken() {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();
  const dispatch = useDispatch();
  const ToggleSnackbar = useCallback(
    (vertical, horizontal, msg, color) =>
      dispatch(toggleSnackbar(vertical, horizontal, msg, color)),
    [dispatch]
  );
  const ApplyThemes = useCallback(
    (theme) => dispatch(applyThemes(theme)),
    [dispatch]
  );
  const SetSessionStatus = useCallback(
    (status) => dispatch(setSessionStatus(status)),
    [dispatch]
  );

  const afterLogin = (data) => {
    Auth.authenticate(data);

    // 设置用户主题色
    if (data["preferred_theme"] !== "") {
      ApplyThemes(data["preferred_theme"]);
    }

    // 设置登录状态
    SetSessionStatus(true);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    if (query.get("redirect")) {
      history.push(query.get("redirect"));
    } else {
      history.push("/home");
    }
    ToggleSnackbar("top", "right", t("login.success"), "success");

    localStorage.removeItem("siteConfigCache");
  };

  const login = () => {
    API.post("/user/sessionByToken", { Token: query.get("token") })
      .then((response) => {
        if (response.rawData.code === 203) {
        } else {
          afterLogin(response.data);
        }
      })
      .catch((error) => {
        ToggleSnackbar("top", "right", error.message, "warning");
      });
  };

  useEffect(() => {
    login();
  }, []);

  return <div>login by token</div>;
}

export default LoginByToken;
